import { Injectable } from "@angular/core";
import { finalize, map, Observable } from "rxjs";

@Injectable({
    providedIn: 'root'
})
export class DownloadService {
    constructor() { }

    downloadBlob(blob: Blob, filename: string, mimeType = 'application/octet-stream') {
        try {
            const url = window.URL.createObjectURL(new Blob([blob], { type: mimeType }));
            const downloadLink = document.createElement('a');
            downloadLink.href = url;
            downloadLink.download = filename;

            document.body.appendChild(downloadLink);
            downloadLink.click();
            document.body.removeChild(downloadLink);

            window.URL.revokeObjectURL(url);
        } catch (error) {
            console.error('Download processing error:', error);
            throw new Error('Failed to process download');
        }
    }

    downloadFromApi<T>(
        request: Observable<T>,
        filename: string,
        mimeType = 'application/octet-stream'
    ): Observable<void> {

        return request.pipe(
            map(blob => {
                this.downloadBlob(blob as unknown as Blob, filename, mimeType);
            }),
            finalize(() => { })
        );
    }
}
